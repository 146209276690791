<template>
  <div class="mt-60">
    <div
      v-if="message !== '' && !registering"
      class="bg-white shadow-lg rounded-md px-5 py-6"
    >
      <div class="text-center">
        <h2 class="text-2xl font-bold mb-3">Registration Successful</h2>
        <p>
          Registration Successful, kindly check <b>{{ user_email }}</b> to
          verify your email
        </p>
        <p v-if="message !== ''" class="mb-3 block text-xs font-semibold">
          Didn't get an email?
          <a class="underline text-primary" @click.prevent="resendVerification"
            >Resend email</a
          >
        </p>
        <router-link to="/odr/signin" class="block text-xs text-gray-600 mt-4"
          >Login to my account</router-link
        >
      </div>
    </div>
    <div v-else class="bg-white shadow-lg rounded-md px-5 py-3">
      <h2 class="text-2xl font-bold mb-2">Sign Up.</h2>
      <p class="mb-5 text-sm">
        Create you account and easily Create dispute against someone
      </p>

      <a-form-model
        ref="signupForm"
        :rules="rules"
        layout="vertical"
        :model="form"
      >
        <div class="grid grid-cols-2 gap-x-2">
          <a-form-model-item has-feedback label="First Name" prop="fname">
            <a-input
              v-model="form.fname"
              :disabled="registering"
              placeholder="First Name"
            />
          </a-form-model-item>
          <a-form-model-item has-feedback label="Last Name" prop="lname">
            <a-input
              v-model="form.lname"
              :disabled="registering"
              placeholder="Last Name"
            />
          </a-form-model-item>
        </div>
        <a-form-model-item has-feedback label="Email Address" prop="email">
          <a-input
            v-model="form.email"
            :disabled="registering || linkHasEmail"
            placeholder="Your working Email address"
          />
        </a-form-model-item>
        <a-form-model-item has-feedback label="Password" prop="password">
          <a-input-password
            v-model="form.password"
            :disabled="registering"
            placeholder="Enter your password"
          />
        </a-form-model-item>
        <a-form-model-item
          has-feedback
          label="Confirm Password"
          prop="confirm_password"
        >
          <a-input-password
            v-model="form.confirm_password"
            :disabled="registering"
            placeholder="Enter your password again"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            class="w-full"
            :loading="registering"
            type="primary"
            @click="onSubmit"
          >
            Sign Up
          </a-button>
        </a-form-model-item>
        <p>
          By clicking on Sign Up, you have agree to our
          <a href="https://legalnaija.com/terms-conditions/" target="_blank"
            >Terms and Condition</a
          >
          and
          <a href="https://legalnaija.com/privacy-policy/" target="_blank"
            >Privacy Policy</a
          >
        </p>

        <p>
          I have an account?
          <router-link to="/odr/signin">Sign In</router-link> here.
        </p>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import authApi from '../../api/auth'
export default {
  name: 'SignUp',
  layout: 'odrauth',
  data() {
    const passwordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (this.form.confirm_password !== '') {
          this.$refs.signupForm.validateField('confirm_password')
        }
        callback()
      }
    }
    const confirmPasswordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== this.form.password) {
        callback(new Error("Two inputs don't match!"))
      } else {
        callback()
      }
    }
    return {
      registering: false,
      message: '',
      error: 'false',
      linkHasEmail: false,
      user_email: '',
      form: {
        email: '',
        password: '',
        fname: '',
        lname: '',
        confirm_password: '',
      },
      rules: {
        email: [
          {
            pattern:
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            required: true,
            message: 'Enter a valid email address',
            trigger: 'blur',
          },
        ],
        password: [
          {
            message: 'Password cannot be empty',
            required: true,
          },
          {
            message: 'Password must be at least 6 characters',
            min: 6,
          },
          {
            trigger: 'change',
            validator: passwordValidator,
          },
        ],
        confirm_password: [
          {
            required: true,
            message: 'Enter your password again',
          },
          {
            validator: confirmPasswordValidator,
            trigger: 'change',
          },
        ],
        fname: {
          required: true,
          trigger: 'blur',
          message: 'First name is required',
        },
        lname: {
          required: true,
          trigger: 'blur',
          message: 'Last name is required',
        },
      },
    }
  },
  head: {
    title: 'ODR Sign Up | Legal Naija',
  },
  created() {
    const emailFromLink = this.$route.query.email
    if (emailFromLink !== undefined) {
      this.linkHasEmail = true
      this.form.email = emailFromLink
    }
  },
  methods: {
    async resendVerification() {
      const req = await authApi(this.axios).resendEmailVerification({
        email: this.user_email,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to send email',
          description: req.message,
        })
        return
      }
      this.$notification.success({
        message: 'Email sent successfully',
        description: req.message,
      })
      this.$router.push('/odr/signin')
    },
    async registerUser() {
      this.message = ''
      this.registering = true
      const req = await authApi(this.axios).register({
        firstName: this.form.fname,
        lastName: this.form.lname,
        email: this.form.email,
        password: this.form.password,
        role: 'user',
      })
      this.user_email = this.form.email
      if (req.error) {
        this.$notification.error({
          message: 'Registration Failed',
          description: req.message,
        })
        this.registering = false
        return
      }
      this.error = false
      this.message = 'Registration Successful'
      this.registering = false
      this.form = {}
    },
    onSubmit() {
      this.$refs.signupForm.validate((valid) => {
        if (valid) {
          this.registerUser()
        } else {
          return false
        }
      })
    },
  },
}
</script>
